jQuery(function ($) {
    function getLabel($element, dataAttribute)
    {
        let label = $element.data(dataAttribute);
        if (label === '') {
            label = $element.text();
        }

        return label;
    }

    $(document).on('click', '[data-datalayer-addtobasket]', function () {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'gaEvent',
            'eventCategory': 'Button',
            'eventAction': 'Add to Cart',
            'eventLabel': getLabel($(this), 'datalayer-addtobasket')
        });
    }).on('click', '[data-datalayer-topnavigation]', function () {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({
            'event': 'gaEvent',
            'eventCategory': 'Top Navigation',
            'eventAction': 'Click',
            'eventLabel': getLabel($(this), 'datalayer-topnavigation')
        });
    });

    $(function () {
        $('[data-datalayer-pagetype]').each(function () {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'pageType': $(this).data('datalayer-pagetype')
            });
        });

        $('[data-datalayer-category]').each(function () {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push({
                'category': $(this).data('datalayer-category')
            });
        });

        $('[data-datalayer-designsize]').each(function () {
            if ($(this).is(':visible')) {
                window.dataLayer = window.dataLayer || [];
                dataLayer.push({
                    'designSize': $(this).data('datalayer-designsize')
                });
            }
        });

        $('[data-datalayer-completeorder]').each(function () {
            window.dataLayer = window.dataLayer || [];
            dataLayer.push($(this).data('datalayer-completeorder'));
        });
    });
});
